<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text">
            <hb-header :divider="false">
                <hb-page-header title="Global Notifications">
                    <template v-slot:description>
                        import { HbGlobalNotification } from 'hummingbird-aviary';
                        <div class="mt-2">If needing to display global notifications in Hummingbird, please contact Zammad Gill for directions. There is global way to do this in Hummingbird which he built, so you need to contact him if you are unsure of what to do. If using this component in a non-Hummingbid app, then use the recommended code provided here on this page below each example as a starting/reference point.</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-tabs v-model="activeTab">
            <v-tab
                :ripple="false"
            >
                Prototype
            </v-tab>
            <v-tab
                :ripple="false"
            >
                Examples
            </v-tab>
        </hb-tabs>

        <v-divider></v-divider>

        <div v-if="activeTab === 0">
            <v-row no-gutters class="mt-3">
                <v-col cols="6" class="pr-6">

                    <hb-global-notification
                        v-model="showNotification"
                        :type="type"
                        :description="description"
                        :list="list"
                        :title="title"
                        :icon="icon"
                        :loading="loading"
                        :progress="progress"
                        :progress-percentage="progressPercentage"
                        :link="link"
                        :link-type="linkType"
                        :link-to="linkTo"
                        :link-href="linkHref"
                        :button="button"
                        :button-type="buttonType"
                        :button-to="buttonTo"
                        :button-href="buttonHref"
                        :persistent="persistent"
                        :timeout="timeout"
                        @close="doSomething()"
                        @link-click="doSomething()"
                        @button-click="doSomething()"
                    >
                        {{ defaultSlot }}
                        <template v-slot:footer>
                            {{ footer }}
                        </template>
                    </hb-global-notification>

                    <hb-card color="#E0F5F5" title="Code + Data">
                        <pre class="px-4" :style="codeExpanded ? 'min-height:406px;height:auto' : 'height:406px;'">
                            <div style="position:absolute;top:-4px;right:12px;" class="d-flex align-center hb-pointer" @click="codeExpanded = !codeExpanded">
                                {{ codeExpanded ? 'Collapse Code' : 'Expand Code' }}
                                <hb-icon>mdi-chevron-down</hb-icon>
                            </div>
&lt;hb-global-notification
    v-model="showNotification"<span v-if="type"><br />&nbsp;&nbsp;&nbsp;&nbsp;type="{{ type }}"</span><span v-if="description"><br />&nbsp;&nbsp;&nbsp;&nbsp;description="{{ description }}"</span><span v-if="list"><br />&nbsp;&nbsp;&nbsp;&nbsp;:list="{{ list }}"</span><span v-if="title"><br />&nbsp;&nbsp;&nbsp;&nbsp;title="{{ title }}"</span><span v-if="icon && type !== 'success' && type !== 'caution' && type !== 'error'"><br />&nbsp;&nbsp;&nbsp;&nbsp;icon="{{ icon }}"</span><span v-if="!progress && loading && type !== 'success' && type !== 'caution' && type !== 'error' "><br />&nbsp;&nbsp;&nbsp;&nbsp;:loading="{{ loading }}"</span><span v-if="!loading && progress && type !== 'success' && type !== 'caution' && type !== 'error' "><br />&nbsp;&nbsp;&nbsp;&nbsp;:progress="{{ progress }}"</span><span v-if="!loading && progress && progressPercentage && type !== 'success' && type !== 'caution' && type !== 'error'"><br />&nbsp;&nbsp;&nbsp;&nbsp;progress-percentage="{{ progressPercentage }}"</span><span v-if="link"><br />&nbsp;&nbsp;&nbsp;&nbsp;link="{{ link }}"</span><span v-if="link && linkType"><br />&nbsp;&nbsp;&nbsp;&nbsp;link-type="{{ linkType }}"</span><span v-if="link && linkTo && linkType !== 'secondary'"><br />&nbsp;&nbsp;&nbsp;&nbsp;link-to="{{ linkTo }}"</span><span v-if="link && linkHref && linkType === 'secondary'"><br />&nbsp;&nbsp;&nbsp;&nbsp;link-href="{{ linkHref }}"</span><span v-if="button"><br />&nbsp;&nbsp;&nbsp;&nbsp;button="{{ button }}"</span><span v-if="button && buttonType"><br />&nbsp;&nbsp;&nbsp;&nbsp;button-type="{{ buttonType }}"</span><span v-if="button && buttonTo"><br />&nbsp;&nbsp;&nbsp;&nbsp;button-to="{{ buttonTo }}"</span><span v-if="button && buttonHref"><br />&nbsp;&nbsp;&nbsp;&nbsp;button-href="{{ buttonHref }}"</span><span v-if="persistent"><br />&nbsp;&nbsp;&nbsp;&nbsp;:persistent="{{ persistent }}"</span><span v-if="timeout"><br />&nbsp;&nbsp;&nbsp;&nbsp;timeout="{{ timeout }}"</span>
    @close="doSomething()"
    @link-click="doSomething()"
    @button-click="doSomething()"
><span v-if="defaultSlot"><br />&nbsp;&nbsp;&nbsp;&nbsp;{{ defaultSlot }}</span><span v-if="footer"><br />&nbsp;&nbsp;&nbsp;&nbsp;&lt;template v-slot:footer><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ footer }}<br />&nbsp;&nbsp;&nbsp;&nbsp;&lt;/template></span>
&lt;/hb-global-notification>
                        </pre>
                    </hb-card>

                    <hb-card title="Slots" class="mt-6">
                        <hb-form label="default">
                            <HbTextarea
                                v-model="defaultSlot"
                                :rows="8"
                                placeholder="Enter Optional Default Content"
                            />
                        </hb-form>

                        <hb-form label="footer" full>
                            <HbTextarea
                                v-model="footer"
                                :rows="8"
                                placeholder="Enter Optional Footer Content"
                            />
                        </hb-form>
                    </hb-card>
                </v-col>

                <v-col cols="6">
                    <hb-card title="Props">

                        <hb-form label="v-model" full>
                            showNotification
                            <HbSwitch
                                class="mt-2"
                                v-model="showNotification"
                                :label="showNotification ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form label="type">
                            <HbSelect
                                v-model="type"
                                :items="types"
                                placeholder="Select Type"
                            />
                        </hb-form>

                        <hb-form label="description">
                            <HbTextField
                                v-model="description"
                                placeholder="Enter Description"
                            />
                        </hb-form>

                        <hb-form label="list" tooltip-body="Valid lists for this functionality can either be a standard error array generated from vee-validate native validation functionality, or can be a simple array of strings.">
                            <HbCombobox
                                v-model="list"
                                label="Type to Add More List Items"
                            />
                        </hb-form>

                        <hb-form label="title">
                            <HbTextField
                                v-model="title"
                                placeholder="Enter Title"
                            />
                        </hb-form>

                        <hb-form label="icon" tooltip-body="'icon' prop cannot be overridden for 'success', 'caution', and 'error' types.">
                            <HbTextField
                                :disabled="type === 'success' || type === 'caution' || type === 'error'"
                                v-model="icon"
                                placeholder="Enter Icon"
                            />
                        </hb-form>

                        <hb-form label="loading" tooltip-body="'loading' prop cannot be used for 'success', 'caution', and 'error' types.">
                            <HbSwitch
                                v-model="loading"
                                :disabled="progress || type === 'success' || type === 'caution' || type === 'error'"
                                :label="loading ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form label="progress" tooltip-body="'progress' prop cannot be used for 'success', 'caution', and 'error' types.">
                            <HbSwitch
                                v-model="progress"
                                :disabled="loading || type === 'success' || type === 'caution' || type === 'error'"
                                :label="progress ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form label="progress-percentage" v-if="progress" tooltip-body="'progress-percentage' prop cannot be used for 'success', 'caution', and 'error' types." full>
                            <v-slider
                                v-model="progressPercentage"
                                :disabled="loading || type === 'success' || type === 'caution' || type === 'error'"
                                class="align-center"
                                :max="100"
                                :min="0"
                                hide-details
                            >
                                <template v-slot:append>
                                    <hb-text-field
                                        :disabled="loading || type === 'success' || type === 'caution' || type === 'error'"
                                        class="mt-2"
                                        condensed
                                        box
                                        medium
                                        v-model="progressPercentage"
                                        v-validate="'min_value:0|max_value:100'"
                                        name="percentage"
                                        :error="errors.has('percentage')"
                                        placeholder="Enter Progress Percentage"
                                    >
                                        <template v-slot:append>
                                            %
                                        </template>
                                    </hb-text-field>
                                </template>
                            </v-slider>
                        </hb-form>

                        <hb-form label="link">
                            <HbTextField
                                v-model="link"
                                placeholder="Enter Link Text"
                            />
                        </hb-form>

                        <hb-form label="link-type">
                            <HbSelect
                                v-model="linkType"
                                :items="linkTypes"
                                placeholder="Select Link Type"
                            />
                        </hb-form>

                        <hb-form label="link-to" v-if="linkType !== 'secondary'">
                            <HbTextField
                                v-model="linkTo"
                                placeholder="Enter Link To Vue Route"
                            />
                        </hb-form>

                        <hb-form label="link-href" v-if="linkType === 'secondary'">
                            <HbTextField
                                v-model="linkHref"
                                placeholder="Enter Link Href"
                            />
                        </hb-form>

                        <hb-form label="button">
                            <HbTextField
                                v-model="button"
                                placeholder="Enter Button Text"
                            />
                        </hb-form>

                        <hb-form label="button-type">
                            <HbSelect
                                v-model="buttonType"
                                :items="buttonTypes"
                                placeholder="Select Button Type"
                            />
                        </hb-form>

                        <hb-form label="button-to">
                            <HbTextField
                                v-model="buttonTo"
                                placeholder="Enter Button To Vue Route"
                            />
                        </hb-form>

                        <hb-form label="button-href">
                            <HbTextField
                                v-model="buttonHref"
                                placeholder="Enter Button Href"
                            />
                        </hb-form>

                        <hb-form label="persistent" tooltip-body="The value for this has been set to true for the prototype only. For actual usage, you should not use this prop as each type already has a predefined value/behavior for persistent vs non-persistent behavior.">
                            <HbSwitch
                                v-model="persistent"
                                :label="persistent ? 'true' : 'false/null'"
                            />
                        </hb-form>

                        <hb-form label="timeout">
                            <HbTextField
                                v-model="timeout"
                                v-validate="'numeric'"
                                name="timeout"
                                :error="errors.has('timeout')"
                                placeholder="Enter Timeout Value"
                            />
                        </hb-form>
                    </hb-card>

                </v-col>
            </v-row>
        </div>

        <div v-if="activeTab === 1">

        <hb-card title="Success Type With Optional Footer Action + Code" class="mt-4 mb-6">

                <div class="d-flex justify-center py-6">
                    <hb-btn @click="success = true">Test Success Type</hb-btn>
                </div>

                <HbGlobalNotification
                    v-model="success"
                    type="success"
                    description="Note has been successfully saved."
                    button="Do Something"
                    @button-click="success = false"
                />

                <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-btn @click="success = true">Test Success Type&lt;/hb-btn>

&lt;HbGlobalNotification
    v-model="success"
    type="success"
    description="Note has been successfully saved."
    button="Do Something"
    @button-click="success = false"
/>
</pre>
                </hb-card>
            </hb-card>

            <hb-card title="Caution Type + Code" class="mt-4 mb-6">

                <div class="d-flex justify-center py-6">
                    <hb-btn @click="caution = true">Test Caution Type</hb-btn>
                </div>

                <HbGlobalNotification
                    v-model="caution"
                    type="caution"
                    description="Amazing caution message."
                    :list="errorList"
                />

                <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-btn @click="caution = true">Test Caution Type&lt;/hb-btn>

&lt;HbGlobalNotification
    v-model="caution"
    type="caution"
    description="Amazing caution message."
/>
</pre>
                </hb-card>
            </hb-card>

            <hb-card title="Error Type + Code" class="mt-4 mb-6">

                <div class="d-flex justify-center py-6">
                    <hb-btn @click="error = true">Test Error Type</hb-btn>
                </div>

                <HbGlobalNotification
                    v-model="error"
                    type="error"
                    description="There are errors in your form, correct them before continuing."
                    :list="errorList"
                />

                <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-btn @click="error = true">Test Error Type&lt;/hb-btn>

&lt;HbGlobalNotification
    v-model="error"
    type="error"
    description="There are errors in your form, correct them before continuing."
    :list="errorList"
/>
</pre>
                </hb-card>
            </hb-card>

            <hb-card title="Default Type + Code" class="mt-4 mb-6">

                <div class="d-flex justify-center py-6">
                    <hb-btn @click="notification = true">Test Default Type</hb-btn>
                </div>

                <HbGlobalNotification
                    v-model="notification"
                    description="Amazing message goes here."
                    link="Download Document"
                    button="Download"
                    @link-clicked="doSomething()"
                    @button-clicked="doSomethingElse()"
                />

                <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbGlobalNotification
    v-model="notification"
    description="Amazing message goes here."
    link="Download Document"
    button="Download"
    @link-clicked="doSomething()"
    @button-clicked="doSomethingElse()"
/>
</pre>
                </hb-card>
            </hb-card>

            <hb-card title="Example Generating Document Global Notification Using Default Type + Looping Loading Example + Code" class="mt-4 mb-6">

                <div class="d-flex justify-center py-6">
                    <hb-btn @click="setGenerateToTrueThenSetCompletedToTrueAfterThreeSeconds()">Test Generating Document Global Notification + Loading</hb-btn>
                </div>

                <HbGlobalNotification
                    v-model="document"
                    icon="mdi-file"
                    title="Generating Document"
                    :description="exampleLoading ? 'We are generating your document, it should be available for download shortly.' : 'Your document has been successfully generated and is ready for download.'"
                    :loading="exampleLoading"
                    :button="!exampleLoading ? 'Download' : ''"
                    @button-click="document = false"
                />

                <hb-card class="pa-6" color="#E0F5F5" no-title>
    <pre>
    &lt;div class="d-flex justify-center py-6">
        &lt;hb-btn @click="setGenerateToTrueThenSetCompletedToTrueAfterThreeSeconds()">Test Generating Document Global Notification&lt;/hb-btn>
    &lt;/div>

    &lt;HbGlobalNotification
        v-model="document"
        icon="mdi-file"
        title="Generating Document"
        :description="exampleLoading ? 'We are generating your document, it should be available for download shortly.' : 'Your document has been successfully generated and is ready for download.'"
        :loading="exampleLoading"
        :button="!exampleLoading ? 'Download' : ''"
        @button-click="document = false"
    />

    setGenerateToTrueThenSetCompletedToTrueAfterThreeSeconds(){
        this.exampleLoading = true;
        this.document = true;
        setTimeout(() => {
            this.exampleLoading = false;
        }, 3000);
    }
    </pre>
                </hb-card>
            </hb-card>

                    <hb-card title="Example Generating Document Global Notification Using Default Type + Progress Percentage Example + Code" class="mt-4 mb-6">

                <div class="d-flex justify-center py-6">
                    <hb-btn @click="progressStart()">Test Generating Document Global Notification + Using Progress Percentage</hb-btn>
                </div>

                <HbGlobalNotification
                    v-model="progressExample"
                    icon="mdi-file"
                    title="Generating Document"
                    :description="progressPercentageValue < 100 ? 'We are generating your document, it should be available for download shortly.' : 'Your document has been successfully generated and is ready for download.'"
                    progress
                    :progress-percentage="progressPercentageValue"
                    :button="progressPercentageValue >= 100 ? 'Download' : ''"
                    @button-click="progressExample = false"
                />

                <hb-card class="pa-6" color="#E0F5F5" no-title>
    <pre>
    &lt;div class="d-flex justify-center py-6">
        &lt;hb-btn @click="progressStart()">Test Generating Document Global Notification + Using Progress Percentage&lt;/hb-btn>
    &lt;/div>

    &lt;HbGlobalNotification
        v-model="progressExample"
        icon="mdi-file"
        title="Generating Document"
        :description="progressPercentageValue &lt; 100 ? 'We are generating your document, it should be available for download shortly.' : 'Your document has been successfully generated and is ready for download.'"
        progress
        :progress-percentage="progressPercentageValue"
        :button="progressPercentageValue >= 100 ? 'Download' : ''"
        @button-click="progressExample = false"
    />

    progressStart(){
        this.progressPercentageValue = 0;
        this.progressExample = true;

        this.interval = setInterval(() => {
            if(this.progressPercentageValue >= 100){
                clearInterval(this.interval);
            }
            this.progressPercentageValue += Math.random() * (15 - 5) + 5;
        }, 1000)
    }
    </pre>
                </hb-card>
            </hb-card>
        
        </div>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

    </div>
</template>
<script type="text/babel">

    import { EventBus } from '../../EventBus.js';   

    export default {
        name: "DesignSystemGlobalNotifications",
        data: function() {
            return {
                activeTab: '',
                showNotification: true,
                codeExpanded: false,
                type: 'error',
                description: 'There are errors in your form, correct them before continuing.',
                list: [ "The email field is required", "The country field is required", "The reason field is required" ],
                title: undefined,
                icon: undefined,
                loading: false,
                progress: false,
                progressPercentage: 25,
                link: undefined,
                linkType: undefined,
                linkTo: undefined,
                linkHref: undefined,
                button: undefined,
                buttonType: 'secondary',
                buttonTo: undefined,
                buttonHref: undefined,
                persistent: true,
                timeout: undefined,
                defaultSlot: '',
                footer: '',

                types: [ 'success', 'caution', 'error', 'default' ],
                linkTypes: [ 'primary', 'secondary' ],
                buttonTypes: [ 'primary', 'secondary', 'destructive' ],

                success: false,
                error: false,
                caution: false,
                notification: false,
                document: false,
                exampleLoading: false,
                interval: 0,
                progressExample: false,
                progressPercentageValue: 0,
                errorList: [ { "id": "66", "vmId": 1234, "field": "email", "msg": "The email field is required", "rule": "required", "scope": "default" }, { "id": "68", "vmId": 1234, "field": "country", "msg": "The country field is required", "rule": "required", "scope": "default" }, { "id": "72", "vmId": 1234, "field": "reason", "msg": "The reason field is required", "rule": "required", "scope": "default" } ],
                propHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'v-model', type: 'boolean', default: 'false', description: 'Set a data property as the v-model with default value of false. When the v-model property value is changed to true, the global notification will show. Turn it back to false to close the global notification or hit the top right corner "x" close button.' },
                    { name: 'type', type: 'string', default: 'undefined', description: 'Set this value to either "success", "caution", "error", or "generate" for standard usages which auto sets the proper color, title, title icon, and other visual stylings for the type of global notification you want to show.' },
                    { name: 'description', type: 'string', default: 'undefined', description: 'Sets the main description content text of the global notification.' },
                    { name: 'list', type: 'array or object', default: 'undefined', description: 'Sets the list items below the main description. Most obvious use case is for form error validation messaging where you would simply pass in the standard vee-validate "errors" array.' },
                    { name: 'title', type: 'string', default: 'undefined', description: 'Sets the header title of the global notification. This will override the default title that is preset by the "type" prop options (i.e. success, error, generate).' },
                    { name: 'icon', type: 'string', default: 'undefined', description: 'Overwrites the header icon of the global notification. This will only work for the "generate" type as "success", "error", and "caution" icons should never be changed. Icons set here should be in "mdi-code" syntax (i.e. "mdi-info", "mdi-close", "mdi-pencil", etc...).' },
                    { name: 'loading', type: 'boolean', default: 'false', description: 'When set to true, this will show a looping loading divider animation. This will not show if the type is set to "success", "caution", or "error".' },
                    { name: 'progress', type: 'boolean', default: 'false', description: 'When set to true, this will allow the use of progress percentage for loading animation instead of a looping load. This will not show if the type is set to "success", "caution", or "error".' },
                    { name: 'progress-percentage', type: 'number', default: '0', description: 'Set the value of the progress percentage from 0 to 100 here if using progress percentages and "progress" prop is set to true.' },
                    { name: 'link', type: 'string', default: 'undefined', description: 'Set the text of optional link.' },
                    { name: 'link-type', type: 'string', default: 'undefined', description: 'Set the link type to "secondary" if the link is to an external link and it will apply the correct blue text color styling. For internal links, you will not need to set this prop to anything as the default value is already set to the "primary" color type.' },
                    { name: 'link-to', type: 'string || object', default: 'undefined', description: 'Sets the vue route to go to when the link is clicked. For internal links. Accepts a string or object. See vue documentation on how to pass name, paths, params, query, etc...' },
                    { name: 'link-href', type: 'string', default: 'undefined', description: 'Sets the href value for the link. Usually for external links.' },
                    { name: 'button', type: 'string', default: 'undefined', description: 'Set the text of optional button.' },
                    { name: 'button-type', type: 'string', default: 'secondary', description: 'Set the button type to "primary" or "destructive" if you need to change the button color for some reason. The default type is "secondary" and should not be changed 99% of the time. If a mockup has a different color here, please verify that the design request is correct with the design team.' },
                    { name: 'button-to', type: 'string || object', default: 'undefined', description: 'Sets the vue route to go to when the button is clicked. For internal links. Accepts a string or object. See vue documentation on how to pass name, paths, params, query, etc...' },
                    { name: 'button-href', type: 'string', default: 'undefined', description: 'Sets the href value for the button. Usually for external links.' },
                    { name: 'persistent', type: 'boolean', default: 'false', description: 'When set to true, this overrides the default timeout for each type and forces the notification to be persistent until the user manually clicks the close button.' },
                    { name: 'timeout', type: 'number', default: 'undefined', description: 'If you need to change the default timeout number, you can use this prop. You usually should not have to do this. But this is here if needed.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'default', description: 'Default slot is for when you want to set additional content to display immediately after the visual output of the "description" and "list" props. Or you can go without setting the "description" and "list" props and just use the default slot for your content instead.' },
                    { name: 'footer', description: 'Optional footer slot is for when you want to add action buttons at the bottom of the global notification. Please separate buttons with the vuetify helper margin class of "ml-2" for standard usage.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@close', description: 'Event for when top right close button icon is clicked.' },
                    { name: '@link-click', description: 'Event for when link is enabled and it is clicked.' },
                    { name: '@button-click', description: 'Event for when button is enable and it is clicked.' }
                ],
            };
        },
        methods: {
            setGenerateToTrueThenSetCompletedToTrueAfterThreeSeconds(){
                this.exampleLoading = true;
                this.document = true;
                setTimeout(() => {
                    this.exampleLoading = false;
                }, 3000);
            },
            progressStart(){
                this.progressPercentageValue = 0;
                this.progressExample = true;

                this.interval = setInterval(() => {
                    if(this.progressPercentageValue >= 100){
                        clearInterval(this.interval);
                    }
                    this.progressPercentageValue += Math.random() * (15 - 5) + 5;
                }, 1000)
            },
            doSomething(){
                // just used as a placeholder method for example purposes
            },
            doSomethingElse(){
                // just used as a placeholder method for example purposes
            }
        }
    }
</script>

<style scoped>

</style>
